import environment from 'utility/environment'

let PROPTAX_BASE = 'https://web.proptaxapi.dev'
if (environment.production) {
	PROPTAX_BASE = 'https://web.proptaxapi.app'
} else if (environment.review) {
	PROPTAX_BASE = 'https://web.proptaxapi.review'
}

let VOCE_DATA_BASE = 'https://propdata-web-linux-funcapp-dev.azurewebsites.net'
if (environment.production) {
	VOCE_DATA_BASE = 'https://propdata-web-linux-funcapp-prod.azurewebsites.net'
} else if (environment.review) {
	VOCE_DATA_BASE = 'https://propdata-web-linux-funcapp-review.azurewebsites.net'
}

const GRIDS_BASE = environment.production
	? 'https://gridsapi.hometaxshield.com'
	: 'https://gridsapi.hometaxshield.dev'

export const WORDPRESS_BASE = 'https://wp.hometaxshield.com'
export const WORDPRESS_API_BASE = `${WORDPRESS_BASE}/wp-json/wp/v2`
export const POST_ORDER_GRIDS = `${GRIDS_BASE}/CompGrid/OrderCompGrids`

export const GET_USER_ME = `${PROPTAX_BASE}/user/me`
export const GET_USER = `${PROPTAX_BASE}/user/GetUserProfileForSlugId/` // + user slugId
export const GET_USER_NOTIFICATIONS = `${PROPTAX_BASE}/User/GetUserNotifications/` // + {userSlugId}
export const POST_UPDATE_USER_NOTIFICATION = `${PROPTAX_BASE}/User/UpdateUserNotification`
export const POST_USER_PROFILE = `${PROPTAX_BASE}/User/UpdateUserProfile`
export const GET_USER_PROPERTIES = ({ userSlugId, year }) =>
	`${PROPTAX_BASE}/User/GetUserPropsDetail/${userSlugId}${year ? `/${year}` : ''}`
export const GET_MEMBERSHIP_PRICE_DETAILS = ({ groupId, planId }) =>
	`${PROPTAX_BASE}/HomeownerPlan/GetMembershipPriceDetails/${groupId}/${planId}`
export const POST_UPGRADE_HOMEOWNER_PLAN = `${PROPTAX_BASE}/HomeownerPlan/UpdateHomeownerPlan`

export const GET_AUTHTOKEN = `${PROPTAX_BASE}/Auth/GetAuthToken/`
export const POST_MAGICLINK = `${PROPTAX_BASE}/Auth/SendMagicLink`
export const POST_CREATE_GROUP = `${PROPTAX_BASE}/Group/CreateGroup`
export const GET_PROPERTY_GROUP_DETAILS = `${PROPTAX_BASE}/Property/GetPropertyDetail`
export const GET_GROUP_DETAIL = `${PROPTAX_BASE}/Group/GetGroupDetail`
export const GET_GROUP_INVOICES = `${PROPTAX_BASE}/Invoice/GetGroupInvoices`
export const GET_INVOICE_HISTORY = `${PROPTAX_BASE}/Invoice/GetBillingHistoryDetails/`
export const POST_GROUP_PROFILE = `${PROPTAX_BASE}/Group/UpdateGroupProfile`

export const POST_VERIFY_PIDS = `${PROPTAX_BASE}/GridOrder/VerifyGridOrderPids`
export const POST_SUBMIT_GRID_ORDER = `${PROPTAX_BASE}/GridOrder/SubmitGridOrderDetails`
export const POST_GET_GRID_ORDER_DETAILS = `${PROPTAX_BASE}/GridOrder/GetGridOrderDetails` // + {gridOrderId}
export const POST_GET_GRID_ORDER_PRICE = `${PROPTAX_BASE}/GridOrder/GetGridOrderPriceDetails`

export const GET_PLANS = ({ groupId = '' }) =>
	`${PROPTAX_BASE}/HomeownerPlan/GetAvailableHomeownerPlans/${groupId}`

export const GET_GROUP_PAYMENT_METHODS = `${PROPTAX_BASE}/Billing/GetGroupPaymentDetail` //AB656770
export const POST_GROUP_DEFAULT_PAYMENT = `${PROPTAX_BASE}/Billing/UpdateDefaultPayment`
export const GET_SETUP_INTENT_CLIENT_SECRET = `${PROPTAX_BASE}/Billing/GetSetupIntentClientSecret`

export const POST_PAY_INVOICE = `${PROPTAX_BASE}/Invoice/PaySuccessFeeInvoiceAmount`
export const POST_PAY_ACH_FAILED_INVOICE = `${PROPTAX_BASE}/Invoice/PayAchFailedInvoiceAmount`
export const POST_PAY_RENEWAL = `${PROPTAX_BASE}/Invoice/PayMembershipRenewalAmount`
export const POST_ADD_PROPERTIES = `${PROPTAX_BASE}/Property/AddProperties`
export const GET_PROPERTY_DOCUMNETS = `${PROPTAX_BASE}/Property/GetAttachments/`
export const POST_PROPERTY_ATTACHMENT = `${PROPTAX_BASE}/Property/AddAttachment`
export const POST_CAN_CANCEL_PROPERTY = `${PROPTAX_BASE}/Property/CanCancelSignupYearProperty`
export const POST_CANCEL_PROPERTY = `${PROPTAX_BASE}/Property/CancelSignupYearProperty`
export const DELETE_PROPERTY_ATTACHMENT = `${PROPTAX_BASE}/Property/DeleteAttachment/`
export const GET_TENANT_SETTINGS = `${PROPTAX_BASE}/Tenant/GetSettings/`
export const GET_TENANT_PROFILE = `${PROPTAX_BASE}/Tenant/GetProfile/`

export const POST_ADD_OR_RENEW_PROPERTY_PRICE_DETAILS = `${PROPTAX_BASE}/HomeownerPlan/GetAddRenewPropertyPriceDetails`
export const POST_ADD_OR_RENEW_PROPERTIES = `${PROPTAX_BASE}/Property/AddRenewProperties`
export const POST_FETCH_RENEWAL_PROPETIES = `${PROPTAX_BASE}/Property/GetGroupRenewalProps`

export const POST_PARTNER = `${PROPTAX_BASE}/Partner/AddPartner`
export const GET_PARTNER_COMMMISSION_TYPES = ({ partnerSlugId }) =>
	`${PROPTAX_BASE}/Partner/GetCommissionTypes/${partnerSlugId ? `/${partnerSlugId}` : ''}`
export const GET_PARTNER_PUBLIC_INFO = `${PROPTAX_BASE}/Partner/GetPartnerPublicInfo/` // expects /[slug]
export const GET_PARTNER_FROM_ID = `${PROPTAX_BASE}/Partner/GetPartnerForId/` // + 75FEAE1B-FF40-4B58-BCFA-B25DFC9A0B52
export const GET_PARTNER_FROM_SLUG = `${PROPTAX_BASE}/Partner/GetPartnerForSlugName/`
export const GET_PARTNER_PROPERTIES = `${PROPTAX_BASE}/Partner/GetPartnerProperties` // + 889C51C2/2021
export const GET_GRID_PARTNER_FROM_SLUG = `${PROPTAX_BASE}/Partner/GetCompGridPartnerForSlug/`
export const POST_PARTNER_UPDATE = `${PROPTAX_BASE}/Partner/UpdatePartner`
export const GET_PARTNER_STATS = `${PROPTAX_BASE}/Partner/GetPartnerStatistics`
export const GET_PARTNER_INTERNAL_MARKETS = `${PROPTAX_BASE}/Partner/GetPartnerInternalMarkets`
export const GET_PARTNER_INDUSTRY_TYPES = `${PROPTAX_BASE}/Partner/GetPartnerIndustryTypes`

export const POST_SEARCH_PROPERTY = `${VOCE_DATA_BASE}/api/v1/propertysearch`
export const POST_SEARCH_PROPERTY_V2 = `${VOCE_DATA_BASE}/api/v2/PropertySearch`
export const POST_SEARCH_PROPERTY_V3 = `${VOCE_DATA_BASE}/api/v3/PropertySearch`
export const GET_COUNTY = `${VOCE_DATA_BASE}/api/v1/countydata`
export const GET_COUNTIES = `${VOCE_DATA_BASE}/api/v1/countylist/tx`
export const GET_PROPERTY = `${VOCE_DATA_BASE}/api/v1/propertydata`
export const GET_STATE = `${VOCE_DATA_BASE}/api/v1/statedata`
