import React, { useEffect } from 'react'
import Router from 'next/router'
import { ThemeProvider, createGlobalStyle } from 'styled-components'
import { library } from '@fortawesome/fontawesome-svg-core'
import { ErrorBoundary } from 'components/common/ErrorBoundary'
import theme from 'styles/theme'
import { BlogProvider } from 'components/providers/BlogProvider'
import { GroupProvider } from 'components/providers/GroupProvider'
import { PartnerProvider } from 'components/providers/PartnerProvider'
import { SelfProvider } from 'components/providers/SelfProvider'
import { AppProvider } from 'components/providers/AppProvider'
import GlobalLoading from 'components/common/GlobalLoading'
import isBrowser from 'utility/isBrowser'
import { COLORS } from 'utility/colors'
import environment from 'utility/environment'
import NProgress from 'nprogress' //nprogress module
import 'nprogress/nprogress.css' //styles of nprogress
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { subdomain } from 'utility/domain'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { TenantWrapper } from 'components/providers/TenantWrapper'
import { GA4_TRACKING_ID } from 'utility/google'
import { GoogleAnalytics } from 'nextjs-google-analytics'
import { init, integrateWithCrisp } from 'utility/logrocket'

init()
integrateWithCrisp()

const queryClient = new QueryClient()

import {
	faSunglasses as faSolidSunglasses,
	faTerminal,
	faLaptop,
	faUserTie as faUserTieSolid,
	faLaptopCode,
	faSmile,
	faDollarSign,
	faFilePdf,
	faPen,
	faBell,
	faUndo,
	faUsers,
	faMegaphone,
	faFileDownload,
	faNetworkWired,
	faThumbsUp,
	faCreditCardFront,
	faHomeHeart,
	faEye,
	faFolder,
	faHandshakeAlt,
	faMapMarkerAlt,
	faQuoteRight,
	faQuoteLeft,
	faCheck,
	faTimes as faSolidTimes,
	faTimesCircle,
	faUserTie,
	faEdit,
	faHome,
	faHandshake,
	faLightbulb,
	faChartLine,
	faChartNetwork,
	faMapPin,
	faEnvelope as fabEnvelope,
	faPhone as fabPhone,
	faChevronLeft as fabChevronLeft,
	faChevronRight as fasChevronRight,
	faChevronUp,
	faChevronDown,
	faCaretRight,
	faClipboardCheck,
	faSackDollar,
	faMagic,
	faUser,
	faClock,
	faBadgeDollar,
	faUserShield,
	faImage,
	faExclamationCircle as faSolidExclamationCircle,
	faList,
	faFileInvoiceDollar as faFileInvoiceDollarSolid,
	faLayerGroup,
	faGraduationCap,
	faGlobe as faGlobeSolid,
	faBrowser,
	faTable,
	faIdCard,
	faFarm,
	faFileCheck,
	faCheckCircle,
} from '@fortawesome/pro-solid-svg-icons'
import {
	faShield,
	faExclamation,
	faDotCircle,
	faInfoCircle,
	faLink,
	faEnvelope,
	faGlobe,
	faPhone,
	faHeart,
	faSearchLocation,
	faPlus,
	faChevronLeft,
	faCommentExclamation,
	faQuestion,
	faExclamationCircle,
	faCalendar,
	faArrowRight,
	faStopwatch,
	faTag,
	faCloudUpload,
	faSunglasses,
	faExternalLink,
	faChevronRight,
	faTrash,
	faHome as faRegularHome,
	faIdCard as faRegularIdCard,
	faFarm as faRegularFarm,
} from '@fortawesome/pro-regular-svg-icons'
import {
	faBars,
	faTimes,
	faHammer,
	faUsers as faLightUsers,
	faFileContract,
	faFileInvoiceDollar,
	faCamera,
	faDollarSign as faLightDollarSign,
} from '@fortawesome/pro-light-svg-icons'

import '@progress/kendo-theme-bootstrap/dist/all.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import 'doka/doka.css'
import { SkeletonPage } from 'components/common/skeletons/SkeletonPage'

// import './App.css';

library.add(
	faLightDollarSign,
	faLightUsers,
	faShield,
	faCheckCircle,
	faUserTieSolid,
	faSolidSunglasses,
	faTerminal,
	faLaptopCode,
	faLaptop,
	faSmile,
	faFileCheck,
	faFilePdf,
	faPen,
	faEye,
	faTrash,
	faRegularHome,
	faRegularIdCard,
	faRegularFarm,
	faHome,
	faIdCard,
	faFarm,
	faLayerGroup,
	faSolidExclamationCircle,
	faBell,
	faUndo,
	faUsers,
	faFileInvoiceDollarSolid,
	faMegaphone,
	faList,
	faImage,
	faExternalLink,
	faFileDownload,
	faCloudUpload,
	faHammer,
	faFileContract,
	faFileInvoiceDollar,
	faCamera,
	faExclamation,
	faStopwatch,
	faTag,
	faPlus,
	faSunglasses,
	faNetworkWired,
	faThumbsUp,
	faCreditCardFront,
	faHomeHeart,
	faFolder,
	faHandshakeAlt,
	faMapMarkerAlt,
	faDotCircle,
	faQuoteRight,
	faQuoteLeft,
	faSolidTimes,
	faCheck,
	faTimesCircle,
	faUserTie,
	faHome,
	faEdit,
	faInfoCircle,
	faHandshake,
	faLightbulb,
	faChartLine,
	faDollarSign,
	faChartNetwork,
	fabPhone,
	fabEnvelope,
	faMapPin,
	fabChevronLeft,
	faChevronLeft,
	faChevronRight,
	fasChevronRight,
	faClipboardCheck,
	faCaretRight,
	faSackDollar,
	faMagic,
	faUser,
	faClock,
	faBadgeDollar,
	faUserShield,
	faLink,
	faEnvelope,
	faGlobe,
	faGlobeSolid,
	faBrowser,
	faPhone,
	faHeart,
	faSearchLocation,
	faCommentExclamation,
	faQuestion,
	faChevronUp,
	faChevronDown,
	faExclamationCircle,
	faCalendar,
	faArrowRight,
	faBars,
	faTimes,
	faGraduationCap,
	faTable,
)

// This resets the base styles set by the browser
const GlobalStyle = createGlobalStyle`
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  main, menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font-family: 'Lato', 'Poppins', sans-serif;
    vertical-align: baseline;
  }
  p{
    text-overflow: ellipsis;
    overflow: hidden;
  }
  a{
    color: inherit;
  }
  a:hover{
    color: inherit;
    text-decoration: none;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, main, menu, nav, section {
    display: block;
  }
  /* HTML5 hidden-attribute fix for newer browsers */
  *[hidden] {
    display: none;
  }
  body {
    /* background-color: ${COLORS.BACKGROUND}; */
    line-height: 1;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100% !important;
    min-height: 100vh;
    /* mobile viewport bug fix */
    min-height: -webkit-fill-available;
    overflow-x: hidden;
  }
  html{
    height: -webkit-fill-available;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  .modal-content {
    /* max-height: 100vh; */
    overflow: hidden;
  }

  input{
    border:1px solid #cccccc;
  }
  
  input:focus { 
    outline: none !important;
    border: 1px solid ${COLORS.BLACK};
    box-shadow: 0 0 10px #dddddd;

    /* outline-color: #acd9dd !important; */
  }
  button:focus { 
    outline: none;
  }

  .rbt-input.focus{
    outline: none !important;
    border: 1px solid ${COLORS.BLACK} !important;
    box-shadow: 0 0 10px #dddddd !important;
  }
  .App {
    text-align: center;
    background-color: #fbf7f5;
  }

  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }

  .main-content {
    margin: 25px;
    padding: 25px;
    background: #f9f1f1;
    border-radius: 10px;
  }

  .rccs {
    margin: inherit;
    /* width: 150px; */
    /* height: 95px; */
  }

  /* Kendo UI */
  .k-editor{
    border-radius: 5px !important;
  }
  .k-toolbar{
    border-top-right-radius: 5px !important;
    border-top-left-radius: 5px !important;
  }

  .name-column {
    text-align: left;
  }

  .description-row {
    text-align: left;
    padding-top: 15px;
    padding-bottom: 5px;
  }

  .details-row {
    border-top: 1px gray solid;
  }

  .second-row {
    padding-top: 15px;
  }

  .maintenance-row {
    border-top: 1px gray solid;
  }

  .parameters-row {
    border-top: 1px gray solid;
  }

  .section-header-col {
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .toolTip{
    width: initial;
    max-width: 300px;
    opacity: 1 !important;
    background-color: white;
    z-index: 99999 !important;
  }
  .toolTipModal p{
    /* white-space: nowrap; */
  }
  .modal-dialog{
    width: 100%;
  }

  .map-container > div:first-of-type{
    width: 100%;
    height: 100%;
  }
  .doka-image-editor {
    --color-background: 237, 237, 237;
    --color-foreground: 0, 0, 0;
  }
  .__react_component_tooltip{
    background-color: #F9F9F9 !important;
  }
  @media (max-width: 768px) {
    .toolTip{
      width: 50%;
    }
  }
`

// replace console.* for disable log on production
if (environment.production) {
	// console.log = () => {}
	console.debug = () => {}
	console.warn = () => {}
	console.error = () => {}
}

const App = ({ Component, pageProps }) => {
	useEffect(() => {
		Router.events.on('routeChangeStart', () => {
			NProgress.start()
		})
		Router.events.on('routeChangeComplete', (url, { shallow }) => {
			isBrowser && !shallow && window.scrollTo(0, 0)
			NProgress.done()
		})
		Router.events.on('routeChangeError', () => {
			NProgress.done()
		})
	}, [])

	// return (
	// 	<div>
	// 		<h1>Undergoing site maintenence</h1>
	// 		<p>We'll be back shortly.</p>
	// 	</div>
	// )

	if (!subdomain) {
		return <SkeletonPage />
	}

	return (
		<ErrorBoundary>
			<>
				<QueryClientProvider client={queryClient}>
					<ThemeProvider theme={theme}>
						<AppProvider>
							<TenantWrapper>
								<SelfProvider>
									<BlogProvider>
										<GroupProvider>
											<PartnerProvider>
												<GoogleAnalyticsWrapper>
													<ToastContainer theme='light' />
													<Component {...pageProps} />
													<GlobalStyle />
													<GlobalLoading />
												</GoogleAnalyticsWrapper>
											</PartnerProvider>
										</GroupProvider>
									</BlogProvider>
								</SelfProvider>
							</TenantWrapper>
						</AppProvider>
					</ThemeProvider>
				</QueryClientProvider>
			</>
		</ErrorBoundary>
	)
}

const GoogleAnalyticsWrapper = ({ children }) => {
	return (
		<>
			<GoogleAnalytics gaMeasurementId={GA4_TRACKING_ID} />
			{children}
		</>
	)
}

export default App
