import { event as sendGAEvent } from 'nextjs-google-analytics'

import { each } from 'underscore'
import environment from 'utility/environment'

export const GA4_TRACKING_ID = environment.production ? 'G-R78J2SJWBM' : 'G-190D5GHELB' // This is your GA4 Measurement id

export const event = ({ action, category, label, value, ...data }) => {
	sendGAEvent(action, {
		category,
		label,
		value,
		...data,
	})
}

export function parseGoogleResponse(components) {
	let addressObj = {}
	each(components, function (component) {
		each(component.types, function (type) {
			if (type === 'street_number') addressObj.number = component.long_name
			if (type === 'route') addressObj.street = component.long_name
			if (type === 'locality') addressObj.city = component.long_name
			if (type === 'administrative_area_level_1') addressObj.state = component.short_name
			if (type === 'country') addressObj.country = component.short_name
			if (type === 'postal_code') addressObj.zipCode = component.long_name
		})
	})

	return {
		...addressObj,
		formatted:
			`${addressObj.number} ${addressObj.street} ${addressObj.city}, ${addressObj.state} ${addressObj.zipCode}`
				.replace(/\s\s+/g, ' ')
				.trim(),
	}
}
